Resumen de la persona que se postulo.
<script>
// import Swal from 'sweetalert2';
import ItemList from "@/components/ItemList/ItemList.vue";

export default {
  name: "ResumenDePostulacion",
  components: {
    ItemList,
  },
  async mounted() {
    this.$isLoading(true);
    await this.$store.getters
      .fetchGet({ path: `Postulacion/hojaDeVida/${this.$route.params.id}` })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("No se pudo obtener la información del postulante");
      })
      .then((result) => {
        console.log(result);
        this.postulanteInformacion = result;
      })
      .catch((error) => {
        console.log(error);
      });

    await this.getNivelesAcademicos();
    await this.getParentescos();
    await this.getCategoriasDeLicencia();

    this.$isLoading(false);
  },
  data() {
    return {
      postulanteInformacion: {},
      seccionesDelFormulario: [
        { name: "datosPersonales", text: "Datos Personales" },
        { name: "educacionFormal", text: "Educación Formal" },
        { name: "educacionNoFormal", text: "Educación No Formal" },
        { name: "experienciaProfesional", text: "Experiencia Profesional" },
        { name: "informacionFamiliar", text: "Información Familiar" },
        { name: "referenciasPersonales", text: "Referencias Personales" },
      ],
      seccionSeleccionada: "Datos Personales",

      nivelesAcademicos: [],
      parentescos: [],
      categoriasDeLicencia: [],
    };
  },
  methods: {
    getBadgeStatus(value) {
      if (value == "Pendiente") {
        return {
          icon: "fas fa-clock",
          badge: "warning",
        };
      } else if (value == "Aprobado") {
        return {
          icon: "fas fa-check-circle",
          badge: "success",
        };
      } else if (value == "Rechazado") {
        return {
          icon: "fas fa-times-circle",
          badge: "danger",
        };
      }
    },
    getNivelesAcademicos() {
      this.$store.getters
        .fetchGet({
          path: `ValuesCatalog/GetListByCodeCatalog/NivelesAcademicos`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error en catalogo");
        })
        .then((result) => {
          this.nivelesAcademicos = result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getParentescos() {
      this.$store.getters
        .fetchGet({ path: `ValuesCatalog/GetListByCodeCatalog/Parentesco` })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error en catalogo");
        })
        .then((result) => {
          this.parentescos = result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategoriasDeLicencia() {
      this.$store.getters
        .fetchGet({
          path: `ValuesCatalog/GetListByCodeCatalog/CategoriaLicencia`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error en catalogo");
        })
        .then((result) => {
          this.categoriasDeLicencia = result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mapNivelAcademico(nivelAcademicoId) {
      return this.nivelesAcademicos.find((item) => item.id === nivelAcademicoId)
        .description;
    },

    mapParentesco(parentescoId) {
      return this.parentescos.find((item) => item.id === parentescoId)
        .description;
    },

    mapCategoriaDeLicencia(categoriaDeLicenciaId) {
      return this.categoriasDeLicencia.find(
        (item) => item.id === categoriaDeLicenciaId
      ).description;
    },
  },
  computed: {
    postulante() {
      const hasInformation = Object.keys(this.postulanteInformacion);
      if (hasInformation) {
        return this.postulanteInformacion.postulante;
      } else {
        return {};
      }
    },
    educacionFormal() {
      const hasInformation = Object.keys(this.postulanteInformacion);
      if (hasInformation) {
        return this.postulanteInformacion.educacionFormal;
      } else {
        return [];
      }
    },
    educacionNoFormal() {
      const hasInformation = Object.keys(this.postulanteInformacion);
      if (hasInformation) {
        return this.postulanteInformacion.educacionNoFormal;
      } else {
        return [];
      }
    },
    experienciaProfesional() {
      const hasInformation = Object.keys(this.postulanteInformacion);
      if (hasInformation) {
        return this.postulanteInformacion.experienciaLaboral;
      } else {
        return [];
      }
    },
    informacionFamiliar() {
      const hasInformation = Object.keys(this.postulanteInformacion);
      if (hasInformation) {
        return this.postulanteInformacion.informacionFamiliar;
      } else {
        return [];
      }
    },
    referenciasPersonales() {
      const hasInformation = Object.keys(this.postulanteInformacion);
      if (hasInformation) {
        return this.postulanteInformacion.referenciaPersonales;
      } else {
        return [];
      }
    },
  },
};
</script>

<template>
  <HXContentCard title="Registro de Postulación" :defaultToBack="true">
    <card class="px-4 py-2">
      <h3>
        <strong>
          {{ postulante.vacante.cargo.nombre }}
        </strong>
      </h3>
      <h4 class="text-white-50 my-2">
        Estado de evaluación:
        <b-badge
          :variant="
            getBadgeStatus(postulante.preseleccionEvaluacion.estado).badge
          "
        >
          <i
            :class="
              getBadgeStatus(postulante.preseleccionEvaluacion.estado).icon
            "
          ></i>
          {{ postulante.preseleccionEvaluacion.estado }}
        </b-badge>
      </h4>
      <section class="buttons btn-group">
        <button
          v-for="seccion in seccionesDelFormulario"
          :key="seccion.name"
          class="btn btn-primary btn-lg"
          :class="{ active: seccion.text == seccionSeleccionada }"
          @click="seccionSeleccionada = seccion.text"
        >
          <b>{{ seccion.text }}</b>
        </button>
      </section>
      <section>
        <h3 class="mt-3">
          <strong>{{ seccionSeleccionada }}</strong>
        </h3>

        <div v-show="seccionSeleccionada == seccionesDelFormulario[0].text">
          <div class="grid-container">
            <ItemList title="Correo Electrónico" :value="postulante.correo" />
            <ItemList
              title="Fecha de Postulación"
              :value="postulante.fechaDePostulacion"
            />
            <ItemList title="Nombres" :value="postulante.nombres" />
            <ItemList title="Apellidos" :value="postulante.apellidos" />
            <ItemList
              title="Tipo de documento"
              :value="postulante.tipoDocumento.descripcion"
            />
            <ItemList
              title="Numero de documento"
              :value="postulante.numeroDocumento"
            />
            <ItemList
              title="Numero celular"
              :value="postulante.telefonoCelular"
            />
            <ItemList
              title="Numero teléfono"
              :value="postulante.telefonoFijo"
            />
            <ItemList
              title="Tipo de licencia de conducción"
              :value="postulante.licenseCategoryId"
            />
            <ItemList
              title="Numero de licencia de conducción"
              :value="postulante.licenseNumber"
            />
            <ItemList
              title="Fecha de Nacimiento"
              :value="postulante.dateBorn"
            />
            <ItemList title="Dirección" :value="postulante.direccion" />
            <ItemList title="Barrio" :value="postulante.neighborhood" />
            <ItemList title="Localidad" :value="postulante.locality" />
            <ItemList title="Estrato" :value="postulante.stratum" />
            <ItemList
              title="Contacto de Emergencia"
              :value="postulante.emergencyContact"
            />
            <ItemList
              title="Número de Contacto de Emergencia"
              :value="postulante.emergencyContactNumber"
            />
            <ItemList title="País" :value="postulante.pais.descripcion" />
            <ItemList
              title="Departamento"
              :value="postulante.departamento.descripcion"
            />
            <ItemList
              title="Municipio"
              :value="postulante.municipio.descripcion"
            />
          </div>
        </div>
        <div v-show="seccionSeleccionada == seccionesDelFormulario[1].text">
          <div
            v-if="educacionFormal.length == 0"
            class="d-flex justify-content-center p-5"
          >
            <h4 class="text-white-50"><i>No hay información</i></h4>
          </div>

          <div
            v-for="(element, idx) in educacionFormal"
            :key="element.id"
            class="my-2"
          >
            <h4>{{ seccionSeleccionada }} {{ idx + 1 }}</h4>
            <div class="grid-container">
              <ItemList
                title="Nivel Académico"
                :value="mapNivelAcademico(element.nivelAcademicoId)"
              />
              <ItemList
                title="Institución"
                :value="element.institucionEducativa"
              />
              <ItemList
                title="Titulo Obtenido"
                :value="element.tituloObtenido"
              />
              <ItemList
                title="Fecha de Graduación"
                :value="element.fechaGraduacion"
              />
              <ItemList title="País" :value="element.pais.descripcion" />
              <ItemList
                title="Departamento"
                :value="element.departamento.descripcion"
              />
              <ItemList
                title="Municipio"
                :value="element.municipio.descripcion"
              />
            </div>
          </div>
        </div>

        <div v-show="seccionSeleccionada == seccionesDelFormulario[2].text">
          <div
            v-if="educacionNoFormal.length == 0"
            class="d-flex justify-content-center p-5"
          >
            <h4 class="text-white-50"><i>No hay información</i></h4>
          </div>

          <div
            v-for="(element, idx) in educacionNoFormal"
            :key="element.id"
            class="my-2"
          >
            <h4>{{ seccionSeleccionada }} {{ idx + 1 }}</h4>
            <div class="grid-container">
              <ItemList
                title="Nombre del curso"
                :value="element.nombreDelCurso"
              />
              <ItemList
                title="Institución"
                :value="element.institucionEducativa"
              />
              <ItemList title="Fecha de Graduación" :value="element.EndDate" />
              <ItemList
                title="Duración del curso en Horas"
                :value="element.horas"
              />
              <ItemList title="País" :value="element.pais.descripcion" />
              <ItemList
                title="Departamento"
                :value="element.departamento.descripcion"
              />
              <ItemList
                title="Municipio"
                :value="element.municipio.descripcion"
              />
            </div>
          </div>
        </div>

        <div v-show="seccionSeleccionada == seccionesDelFormulario[3].text">
          <div
            v-if="experienciaProfesional.length == 0"
            class="d-flex justify-content-center p-5"
          >
            <h4 class="text-white-50"><i>No hay información</i></h4>
          </div>

          <div
            v-for="(element, idx) in experienciaProfesional"
            :key="element.id"
            class="my-2"
          >
            <h4>{{ seccionSeleccionada }} {{ idx + 1 }}</h4>
            <div class="grid-container">
              <ItemList
                title="Nombre de la empresa"
                :value="element.nombreEmpresa"
              />
              <ItemList
                title="Tiempo laborado (Meses)"
                :value="element.mesesLaborados"
              />
              <ItemList title="Fecha de inicio" :value="element.fechaInicio" />
              <ItemList title="Fecha de salida" :value="element.fechaFin" />
              <ItemList title="Cargo" :value="element.cargo" />
              <ItemList
                title="Motivo del retiro"
                :value="element.motivoRetiro"
              />
              <ItemList
                title="Teléfono de referencia"
                :value="element.telefono"
              />
              <ItemList title="Jefe inmediato" :value="element.jefeInmediato" />
              <ItemList title="País" :value="element.pais.descripcion" />
              <ItemList
                title="Departamento"
                :value="element.departamento.descripcion"
              />
              <ItemList
                title="Municipio"
                :value="element.municipio.descripcion"
              />
            </div>
          </div>
        </div>

        <div v-show="seccionSeleccionada == seccionesDelFormulario[4].text">
          <div
            v-if="informacionFamiliar.length == 0"
            class="d-flex justify-content-center p-5"
          >
            <h4 class="text-white-50"><i>No hay información</i></h4>
          </div>

          <div
            v-for="(element, idx) in informacionFamiliar"
            :key="element.id"
            class="my-2"
          >
            <h4>{{ seccionSeleccionada }} {{ idx + 1 }}</h4>
            <div class="grid-container">
              <ItemList
                title="Parentesco"
                :value="mapParentesco(element.parentescoId)"
              />
              <ItemList
                title="Fecha de nacimiento"
                :value="element.fechaNacimiento"
              />
              <ItemList title="Nombres" :value="element.nombres" />
              <ItemList title="Apellidos" :value="element.apellidos" />
              <ItemList
                title="Tipo de documento"
                :value="element.tipoDocumento.descripcion"
              />
              <ItemList
                title="Numero de documento"
                :value="postulante.numeroDocumento"
              />
              <ItemList title="País" :value="element.pais.descripcion" />
              <ItemList
                title="Departamento"
                :value="element.departamento.descripcion"
              />
              <ItemList
                title="Municipio"
                :value="element.municipio.descripcion"
              />
            </div>
          </div>
        </div>

        <div v-show="seccionSeleccionada == seccionesDelFormulario[5].text">
          <div
            v-if="referenciasPersonales.length == 0"
            class="d-flex justify-content-center p-5"
          >
            <h4 class="text-white-50"><i>No hay información</i></h4>
          </div>

          <div
            v-for="(element, idx) in referenciasPersonales"
            :key="element.id"
            class="my-2"
          >
            <h4>{{ seccionSeleccionada }} {{ idx + 1 }}</h4>
            <div class="grid-container">
              <ItemList
                title="Nombres y apellidos"
                :value="element.nombresYApellidos"
              />
              <ItemList title="Ocupación" :value="element.ocupacion" />
              <ItemList title="Numero celular" :value="element.telefono" />
              <ItemList title="Direccion" :value="element.direccion" />
              <ItemList title="País" :value="element.pais.descripcion" />
              <ItemList
                title="Departamento"
                :value="element.departamento.descripcion"
              />
              <ItemList
                title="Municipio"
                :value="element.municipio.descripcion"
              />
            </div>
          </div>
        </div>
      </section>
    </card>
  </HXContentCard>
</template>

<style scoped>
.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  border-radius: 3px;
}

.buttons > * {
  border-radius: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  color: #ffffff;
  /* Texto blanco en modo oscuro */
  padding: 20px;
  /* Márgenes alrededor del grid */
}

.titulo {
  font-weight: bold;
  color: #ff7f50;
  margin-bottom: 5px;
}

.descripcion {
  color: #ddd;
  margin: 0;
}
</style>
